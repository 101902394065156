'use client'

import { Text } from '@design-system/src/components/Text'
// import { WysiwygReader } from '@design-system/src/components/WysiwygReader'
import { isUserSubscribedToPublication } from 'app/sites/[site]/[[...page]]/_utils/publication-utils'
import { useSession } from 'next-auth/react'
import { BlockPubPromoCustomPageData } from 'next-public-site/app/sites/[site]/[[...page]]/_utils/typescript-utils'
import Link from 'next/link'
import { FC, useEffect, useRef, useState } from 'react'
import { PAGES_NEXT_PUBLIC_SITES } from 'src/utils/route-utils'

import { DEFAULT_GIFT_PROMO_CUSTOM_TITLE } from '@shared/constants'

import { BlockModal } from '../../BlockModal'
import { StarredGiftPlan } from '../BlockPubGift/StarredGiftPlan'
import { isTimeSinceLastModalOverXHoursOld } from './helpers'

export const GiftStripe: FC<BlockPubPromoCustomPageData> = props => {
  const { stripePlans, stripeSupportPlans, connectedSubscriptionSystem, customTitle, popupTimeInSeconds, disabled } =
    props
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const timeoutId = useRef<NodeJS.Timeout>()

  const { data: session, status } = useSession()
  const userEmail = session?.user?.email

  const isUserSubscribed = isUserSubscribedToPublication({
    status,
    session,
    connectedSubscriptionSystem,
    stripePlans,
    stripeSupportPlans,
  })

  const showGiftModal = !isUserSubscribed && status !== 'loading' && !disabled && props.starredGiftPlan
  // don't want to incentivize users to change for cheaper plans

  useEffect(() => {
    if (status === 'loading') return
    const lastModalTimestamp = localStorage.getItem(localstorageKey)

    const shouldModalOpen = () =>
      showGiftModal &&
      ((isUserSubscribed && isTimeSinceLastModalOverXHoursOld(lastModalTimestamp, 30 * 24)) ||
        (!isUserSubscribed && isTimeSinceLastModalOverXHoursOld(lastModalTimestamp, 4 * 24)))

    if (shouldModalOpen()) {
      // set timeout to open modal after popupTimeInSeconds
      timeoutId.current = setTimeout(() => {
        if (shouldModalOpen()) setModalIsOpen(true)
        localStorage.setItem(localstorageKey, new Date().toISOString())
      }, popupTimeInSeconds * 1000)
    }

    return () => {
      if (timeoutId.current) clearTimeout(timeoutId.current)
    }
  }, [showGiftModal, isUserSubscribed, status])

  return showGiftModal && props.starredGiftPlan ? (
    <BlockModal
      openModal={modalIsOpen}
      onCloseModalCallback={setModalIsOpen}
      title={customTitle || DEFAULT_GIFT_PROMO_CUSTOM_TITLE}>
      <StarredGiftPlan
        {...props}
        {...props.starredGiftPlan}
        userEmail={userEmail}
        showFixedBanner={false}
        authenticationStatus={status}
        variant="small"
      />
      <Text variant="caption" css={{ color: '$gs11', mt: '$2' }}>
        * For plan details, please click{' '}
        <Link href={PAGES_NEXT_PUBLIC_SITES.manageSubscriptionUrl}>
          <Text variant="caption" css={{ color: '$gs11' }} isLink={true}>
            here
          </Text>
        </Link>
      </Text>
    </BlockModal>
  ) : null
}

const localstorageKey = 'giftModalTimestamp'
