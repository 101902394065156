'use client'

import { Text } from '@design-system/src/components/Text'
// import { WysiwygReader } from '@design-system/src/components/WysiwygReader'
import { loadStripe } from '@stripe/stripe-js/pure'
import { isUserSubscribedToPublication } from 'app/sites/[site]/[[...page]]/_utils/publication-utils'
import { useSession } from 'next-auth/react'
import { BlockPubPromoCustomPageData } from 'next-public-site/app/sites/[site]/[[...page]]/_utils/typescript-utils'
import Link from 'next/link'
import { FC, useEffect, useRef, useState } from 'react'
import { PAGES_NEXT_PUBLIC_SITES } from 'src/utils/route-utils'

import { DEFAULT_PROMO_CUSTOM_TITLE } from '@shared/constants'

import { BlockModal } from '../../BlockModal'
import { StripePromos } from '../../StripePromos'
import { isTimeSinceLastModalOverXHoursOld } from './helpers'

export const PromoStripe: FC<BlockPubPromoCustomPageData> = ({
  stripePromotions,
  stripePlanOrderByPriceId,
  stripePlans,
  createSubscriberWithStripeAccountApiUrl,
  stripePublishableKey,
  stripeSupportPlans,
  connectedSubscriptionSystem,
  updateSubscriberWithStripeAccountApiUrl,
  customTitle,
  popupTimeInSeconds,
  disabled,
}) => {
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const timeoutId = useRef<NodeJS.Timeout>()

  const { data: session, status } = useSession()

  const availableStripePlansOrderedByPriceId = stripePlanOrderByPriceId?.filter(priceId =>
    stripePlans.some(plan => plan.stripePriceIdMonthly === priceId || plan.stripePriceIdYearly === priceId),
  )

  const availablePlanHaveAtLeastOnePromo = Boolean(
    availableStripePlansOrderedByPriceId?.some(priceId => {
      const plan = stripePlans.find(
        plan => plan.stripePriceIdMonthly === priceId || plan.stripePriceIdYearly === priceId,
      )
      const promo = stripePromotions.find(
        promo => promo.couponId === plan?.promoCouponIdMonthly || promo.couponId === plan?.promoCouponIdYearly,
      )
      return Boolean(promo)
    }),
  )

  const isUserSubscribed = isUserSubscribedToPublication({
    status,
    session,
    connectedSubscriptionSystem,
    stripePlans,
    stripeSupportPlans,
  })

  const showPromo = !isUserSubscribed && status !== 'loading' && !disabled && availablePlanHaveAtLeastOnePromo // don't want to incentivize users to change for cheaper plans

  useEffect(() => {
    const lastModalTimestamp = localStorage.getItem(localstorageKey)
    // proceed only if localModalTimestamp is over 3 days old
    if (showPromo && isTimeSinceLastModalOverXHoursOld(lastModalTimestamp, 4 * 24)) {
      // set timeout to open modal after popupTimeInSeconds
      timeoutId.current = setTimeout(() => {
        if (isTimeSinceLastModalOverXHoursOld(lastModalTimestamp, 4 * 24)) setModalIsOpen(true)
        localStorage.setItem(localstorageKey, new Date().toISOString())
      }, popupTimeInSeconds * 1000)
    }
    return () => {
      if (timeoutId.current) clearTimeout(timeoutId.current)
    }
  }, [showPromo])

  return showPromo ? (
    <BlockModal
      openModal={modalIsOpen}
      onCloseModalCallback={setModalIsOpen}
      title={customTitle || DEFAULT_PROMO_CUSTOM_TITLE}>
      <StripePromos
        stripePromotions={stripePromotions}
        availablePlanPriceIdsOrderedFromMostToLeastImportant={availableStripePlansOrderedByPriceId}
        stripePlans={stripePlans}
        status={status}
        userHasASubscription={isUserSubscribed}
        loadStripe={loadStripe}
        stripePublishableKey={stripePublishableKey}
        createSubscriberWithStripeAccountApiUrl={createSubscriberWithStripeAccountApiUrl}
        updateSubscriberWithStripeAccountApiUrl={updateSubscriberWithStripeAccountApiUrl}
      />
      <Text variant="caption" css={{ color: '$gs11', mt: '$2' }}>
        * For plan details, please click{' '}
        <Link href={PAGES_NEXT_PUBLIC_SITES.manageSubscriptionUrl}>
          <Text variant="caption" css={{ color: '$gs11' }} isLink={true}>
            here
          </Text>
        </Link>
      </Text>
    </BlockModal>
  ) : null
}

const localstorageKey = 'promoModalTimestamp'
